import axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from '../Enum';
import { decryptData, encryptData, getToken } from './service';
import BASE_URL from './constants';

export interface TokenType {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  emailVerified: boolean;
  role: string;
}

const getAccessFromRefreshToken = async (
  refToken: string,
): Promise<TokenType> => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/refresh-token`, {
      refreshToken: decryptData(refToken),
    });
    const { accessToken, refreshToken } = response.data;
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, encryptData(accessToken));
    localStorage.setItem(LocalStorage.REFRESH_TOKEN, encryptData(refreshToken));
    return response.data;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
};

const ApiRequest = async (
  url: string,
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  data = {},
  isUseAccessToken = true,
  companyId?: string,
) => {
  const apiRequestPayload: AxiosRequestConfig = {
    url: BASE_URL + url,
    method,
    data,
    headers: {
      'x-company-id': companyId || undefined,
      'Api-version': 0,
      Authorization: getToken(isUseAccessToken),
    },
    responseType: 'json',
  };

  try {
    const response = await axios(apiRequestPayload);
    return response.data;
  } catch (error) {
    const refreshToken = localStorage.getItem(LocalStorage.REFRESH_TOKEN);

    if (error.response?.status === 401 && isUseAccessToken && refreshToken) {
      try {
        const tokens = await getAccessFromRefreshToken(refreshToken);
        const retryResponse = await axios({
          ...apiRequestPayload,
          headers: {
            'x-company-id': companyId || undefined,
            'Api-version': 0,
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });
        return retryResponse.data;
      } catch (retryError) {
        console.error('Retry request failed:', retryError);
        throw retryError;
      }
    }

    throw error;
  }
};

export default ApiRequest;
